import { useState } from "react";
import { Button, Col, List, Row, Tooltip } from "antd";

import AppLayout from "../../components/Layout";
import { EditOutlined } from "@ant-design/icons";
import { EditProfileModal } from "../../components/EditProfileModal";
import { ChangePasswordModal } from "../../components/ChangePasswordModal";
import { getUserProfile } from "../../services/commonController";
import { getUserData } from "../../utils";
import { useQuery } from "@tanstack/react-query";

const Settings = () => {

  const [isEditProfileModalVisible, setIsEditProfileModalVisible] = useState(false);
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
  const userInfo = getUserData();

  const data = [
    {
      id: 1,
      rowName: "editProfile",
      content: (
        <Row className="flex items-center font-bold text-lg  w-full justify-between ">
          <Col>Edit Profile</Col>
          <Col>
            <Tooltip title="Edit Profile">
              <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => setIsEditProfileModalVisible(true)}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
    {
      id: 2,
      rowName: "changePassword",
      content: (
        <Row className="flex items-center font-bold text-lg justify-between w-full">
          <Col>Change Password</Col>
          <Col>
            <Tooltip title="Change Password">
              <Button
                icon={<EditOutlined />}
                type="primary"
                onClick={() => setIsChangePasswordModalVisible(true)}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  const handleProfileModalVisibility = () => setIsEditProfileModalVisible(false);
  const handleChangePasswordModalVisibility = () =>  setIsChangePasswordModalVisible(false);

  const { data: userProfileResponse } = useQuery({
    queryKey: ["user-profile"],
    queryFn: () => getUserProfile(userInfo?.user_name),
  });
  
  const userProfile = userProfileResponse?.data?.data;
  
  return (
    <AppLayout moduleName="Settings">
      <Row className="mb-64 gap-4 w-full">
        {isEditProfileModalVisible && (
          <EditProfileModal
            userInfo={userProfile}
            isModalVisible={isEditProfileModalVisible}
            handleProfileModalVisibility={handleProfileModalVisibility}
          />
        )}
        {isChangePasswordModalVisible && (
          <ChangePasswordModal
            isModalVisible={isChangePasswordModalVisible}
            handleModalVisibility={handleChangePasswordModalVisibility}
          />
        )}
        <List
          style={{ width: "100%" }}
          bordered
          dataSource={data}
          renderItem={(item) => <List.Item>{item?.content}</List.Item>}
        />
      </Row>
    </AppLayout>
  );
};

export default Settings;
