import React, { SetStateAction, useState, Dispatch } from "react";
import { Col, Layout, Row, theme, Typography } from "antd";

import Sidebar from "../Sidebar";
import Navbar from "../Navbar";

const { Text } = Typography;
const { Content } = Layout;

const AppLayout = ({ children, moduleName, extraField }: any) => {
  
  const [collapsed, setCollapsed]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState(false);
  
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const contentBodyStyle = {
    margin: "24px 16px",
    padding: 24,
    minHeight: 280,
    background: colorBgContainer,
    borderRadius: borderRadiusLG,
  };

  return (
    <Layout>
      <Sidebar collapsed={collapsed} />
      <Layout>
        <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />

        <Content style={contentBodyStyle}>
          <Row className="flex justify-between pr-4 pb-6">
            <Text className="text-3xl font-bold">{moduleName}</Text>
            <Col>{extraField || ""}</Col>
          </Row>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
