import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin, Typography } from "antd";

export function CallInProgressModal({ open }: { open?: boolean }) {
  return (
    <Modal
      open={open}
      footer={null}
      closable={false}
      width={200}
      className="text-center call-inprogress-card"
    >
      <Typography.Text className="font-bold pr-4">
        Call In Progress
      </Typography.Text>
      <Spin indicator={<LoadingOutlined spin />} />
    </Modal>
  );
}
