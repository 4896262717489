import { useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { createUser, getAllRoles, getManagers } from "../../services/adminController";
import { SubmitButton } from "../SubmitButton";
import { validateFirstName, validateLastName, validatePhoneNumber, isEmpty } from "../../utils";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type AllFormValuesTypes = {
  emailId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phoneNumber?: string | undefined;
  role?: string | undefined | number;
  manager: {
    disabled?: boolean | undefined;
    key: string | undefined;
    label: string | undefined;
    title?: string | undefined;
    value: string | undefined;
  };
};

export default function UserForm({ open, setOpen }: any) {

  const TAG = "UserForm: ";
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const queryClient = useQueryClient();
  const [allFormValues, setAllFormValues] = useState<AllFormValuesTypes>();
  const isManagerRequired = allFormValues?.role && (allFormValues?.role === "Agent" || allFormValues?.role === "Legal-Agent");
  const [roleSelected, setRoleSelected] = useState<string>("");

  const { mutate: createUserHandler } = useMutation({
    mutationFn: createUser,
    mutationKey: ["createUser"],
    onSuccess: (res, variables, context) => {
      if (res.status === 200) {
        setOpen(false);
        form.resetFields();
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["managers"] });
        setConfirmLoading(false);
        notification.success({ message: "Success", description: res.data.message });
      }
    },
    onError: (error: any) => {
      console.error("Error creating user:", error);
      notification.error({ message: "Success", description: error.response.data.message });
      setConfirmLoading(false);
    },
  });

  const { data: managersResponse } = useQuery({
    queryKey: ["managers"],
    queryFn: getManagers,
  });

  const handleOk = () => {
    setConfirmLoading(true);
    form.validateFields().then((res: any) => {
      const createUserPayload = {
        firstName: res?.firstName || "",
        lastName: res?.lastName || "",
        email: res?.emailId || "",
        realmRoles: [res?.role],
        mobileNo: res?.phoneNumber,
        managerId: isManagerRequired ? Number(allFormValues.manager?.key) : 0,
      };
      createUserHandler(createUserPayload);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  const { data: rolesResponse } = useQuery({
    queryKey: ["roles"],
    queryFn: getAllRoles,
  });
  
  const rolesList = rolesResponse?.data;

  const getFilteredManagersList = () => {
    let roleToFilter: string = "";
    if (roleSelected === "Agent") roleToFilter = "Agent-Manager";
    if (roleSelected === "Legal-Agent") roleToFilter = "Legal-Manager";
    const retManagers = managersResponse?.data.filter((item: any) => item.roleAssign === roleToFilter);
    return isEmpty(retManagers) === true ? [] : retManagers;
  };
  const filteredManagersList = getFilteredManagersList();

  // console.log(TAG + "roleSelected", roleSelected);

  return (
    <Modal
      open={open}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={null}
    >
      <Card title="Create User">
        <Form
          {...layout}
          form={form}
          name="user-form"
          style={{ maxWidth: 600 }}
          onValuesChange={(changedValue, allValues) =>
            setAllFormValues(allValues)
          }
          onFinish={handleOk}
        >

          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true }, { validator: validateFirstName }]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
              },
              {
                validator: validateLastName,
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="emailId"
            label="Email ID"
            rules={[
              { required: true },
              { type: "email", message: "Please enter a valid email id." },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[{ required: true }, { validator: validatePhoneNumber }]}
          >
            <Input allowClear maxLength={10} />
          </Form.Item>

          <Form.Item name="role" label="Role" rules={[{ required: true }]}>
            <Select
              placeholder="Select a Role"
              onChange={(value) => {
                setRoleSelected(value);
                if (value !== "Agent" || value !== "Legal-Agent") {
                  form.resetFields(["manager"]);
                }
              }}
            >
              {rolesList?.map((item: any) => {
                return (<Option key={item?.id} value={item?.name}> {item?.name} </Option>);
              })}
            </Select>
          </Form.Item>

          {isManagerRequired && (
            <Form.Item
              name="manager"
              label="Manager"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select Manager" labelInValue>
                {filteredManagersList?.map((item: any) => {
                  return (
                    <Option
                      key={item?.id || ""}
                      value={`${item?.firstName} ${item?.lastName}`}
                    >
                      {`${item?.firstName} ${item?.lastName}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          <Row className="flex justify-end gap-2">
            <Button onClick={handleCancel}>Cancel</Button>
            <SubmitButton form={form}>Create User</SubmitButton>
          </Row>

        </Form>
      </Card>
    </Modal>
  );
}
