import axios, { AxiosResponse } from "axios";
import {
  AUDIT_LOGS_ENDPOINT,
  CALL_LOG_ENDPOINT,
  COMMON_AGENTS_ENDPOINT,
  COMMON_CLIENT_LIST_ENDPOINT,
  DOWNLOAD_CASES_ENDPOINT,
  DPD_STATUS_ENPOINT,
  EMAIL_TEMPLATE_ENDPOINT,
  GET_RECORDING_ENDPOINT,
  GET_USER_PROFILE_ENPOINT,
  MAIL_LOG_ENDPOINT,
  POST_CASE_COMMENT_ENDPOINT,
  REFRESH_EMAIL_LOGS_ENDPOINT,
  RESET_PASSWORD_ENPOINT,
  SEND_EMAIL_ENDPOINT,
  SEND_SMS_ENDPOINT,
  SEND_WHATSAPP_ENDPOINT,
  SMS_LOG_ENDPOINT,
  SMS_TEMPLATES_ENDPOINT,
  UPDATE_USER_PROFILE_ENDPOINT,
  WHATSAPP_LOG_ENDPOINT,
  WHATSAPP_TEMPLATES_ENDPOINT,
  WORKFLOW_STATUS_ENPOINT,
  PREVIEW_EMAIL,
  PREVIEW_SMS,
  DEMAND_NOTICE,
  LEGAL_NOTICE,
  JUDICIAL_ACTION
} from "../constants/endpoints";
import { getUserData } from "../utils";

type ResetPasswordBodyTypes = {
  newPassword: string | "";
  oldPassword: string | "";
};

export type UpdateUserProfileTypes = {
  email: string | "";
  firstName: string | "";
  lastName: string | "";
  mobileNo: string | "";
};

type GetCallRecordingPayloadTypes = {
  callId: string;
  caseId: string;
};

type PostCaseCommentPayloadType = {
  caseId: string | "";
  comment: string | "";
};
export async function getClientList() {
  const res = await axios.get(COMMON_CLIENT_LIST_ENDPOINT);
  return res.data;
}

export function getCommonAgents() {
  return axios.get(COMMON_AGENTS_ENDPOINT);
}

export function getCallLogs(
  caseId: string,
  pageNumber: number,
  pageSize: number
) {
  const endpoint = `${CALL_LOG_ENDPOINT}${caseId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return axios.get(endpoint);
}
export function getSMSLogs(
  caseId: string,
  pageNumber: number,
  pageSize: number
) {
  const endpoint = `${SMS_LOG_ENDPOINT}${caseId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return axios.get(endpoint);
}
export function getMailLogs(
  caseId: string,
  pageNumber: number,
  pageSize: number
) {
  const endpoint = `${MAIL_LOG_ENDPOINT}${caseId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return axios.get(endpoint);
}
export function getWhatsappLogs(
  caseId: string,
  pageNumber: number,
  pageSize: number
) {
  const endpoint = `${WHATSAPP_LOG_ENDPOINT}${caseId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return axios.get(endpoint);
}

export function getDpdStatus() {
  return axios.get(DPD_STATUS_ENPOINT);
}
export function getWorkflowStatus() {
  return axios.get(WORKFLOW_STATUS_ENPOINT);
}

export function downloadCases(source: string): Promise<Blob> {
  const endpoint = `${DOWNLOAD_CASES_ENDPOINT}${source}`;
  const userData = getUserData();
  const bearerToken = userData?.access_token || "";
  const axiosInstance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: `Bearer ${bearerToken}`,
    },
    responseType: "blob",
  });

  return axiosInstance
    .get(endpoint)
    .then((response: AxiosResponse<Blob>) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error downloading cases:", error);
      throw new Error("Failed to download cases.");
    });
}

export function resetPassword(body: ResetPasswordBodyTypes) {
  return axios.put(RESET_PASSWORD_ENPOINT, body);
}

export function getSmsTemplates() {
  return axios.get(SMS_TEMPLATES_ENDPOINT);
}

export function sendSMS({ caseId, tempId }: any) {
  const endpoint = `${SEND_SMS_ENDPOINT}${caseId}&tempId=${tempId}`;
  return axios.get(endpoint);
}

export function getWhatsappTeplates() {
  return axios.get(WHATSAPP_TEMPLATES_ENDPOINT);
}

export async function sendDemandNoticeHit(caseId: string) {
  const endpoint = `${DEMAND_NOTICE}${caseId}`;
  const res = await axios.get(endpoint);
  if (res.status === 200) {
    return res.data;
  }
}

export async function sendLegalNoticeHit(caseId: string) {
  const endpoint = `${LEGAL_NOTICE}${caseId}`;
  const res = await axios.get(endpoint);
  if (res.status === 200) {
    return res.data;
  }
}

export async function judicialActionHit(caseId: string) {
  const endpoint = `${JUDICIAL_ACTION}${caseId}`;
  const res = await axios.get(endpoint);
  if (res.status === 200) {
    return res.data;
  }
}

export function sendWhatsapp({ caseId, verticalName, verticalId }: any) {
  const endpoint = `${SEND_WHATSAPP_ENDPOINT}${caseId}&id=${verticalId}&vertical=${verticalName}`;
  return axios.get(endpoint);
}

export function getEmailTemplates() {
  return axios.get(EMAIL_TEMPLATE_ENDPOINT);
}

export function getTemplatePreview({caseId, tempId}: any) {
  const endpoint = `${PREVIEW_EMAIL}?caseId=${caseId}&tempId=${tempId}`;
  return axios.get(endpoint);
}

export function getSMSTemplatePreview({caseId, tempId}: any) {
  const endpoint = `${PREVIEW_SMS}?caseId=${caseId}&tempId=${tempId}`;
  return axios.get(endpoint);
}

export function sendEmail({ caseId, tempId }: any) {
  const endpoint = `${SEND_EMAIL_ENDPOINT}${caseId}&tempId=${tempId}`;
  return axios.get(endpoint);
}

export function getUserProfile(email: string) {
  const endpoint = `${GET_USER_PROFILE_ENPOINT}${email}`;
  return axios.get(endpoint);
}

export function updateUserProfile(payload: UpdateUserProfileTypes) {
  const { firstName, lastName, mobileNo, email } = payload;
  const endpoint = `${UPDATE_USER_PROFILE_ENDPOINT}${email}`;
  return axios.put(endpoint, { firstName, lastName, mobileNo });
}

export function getAuditLogs(
  caseId: string,
  pageNumber: number,
  pageSize: number
) {
  const endpoint = `${AUDIT_LOGS_ENDPOINT}${caseId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return axios.get(endpoint);
}

export function getRecordingUrl(
  getCallRecordingPayloadTypes: GetCallRecordingPayloadTypes
) {
  const { callId = "", caseId = "" } = getCallRecordingPayloadTypes;
  const endpoint = `${GET_RECORDING_ENDPOINT}callUuid=${callId}&caseId=${caseId}`;
  return axios.get(endpoint);
}

export function postCaseComment(payload: PostCaseCommentPayloadType) {
  return axios.post(POST_CASE_COMMENT_ENDPOINT, payload);
}

export function refreshEmailLogs({ caseId, emailId }: any) {
  const endpoint = `${REFRESH_EMAIL_LOGS_ENDPOINT}${caseId}&emailUuid=${emailId}`;
  return axios.get(endpoint);
}
