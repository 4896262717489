import { Navigate } from "react-router-dom";
import LogRocket from 'logrocket';

import RootLayout from "../RootLayout";
import { useAuth } from "../contexts/AuthContext";
import { getUserData } from "../utils";

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated) return <Navigate to="/login" />;
  const userInfo = getUserData();

  LogRocket.identify(userInfo?.user_name, {
    roleName: userInfo?.roleName,
    fullName: userInfo?.fullName,
    emailVerified: userInfo?.emailVerified,
    user_name: userInfo?.user_name
  });

  return <RootLayout />;
};

export default PrivateRoutes;
