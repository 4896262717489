import axios from "axios";
import {
  UPDATE_PASSWORD_ENDPOINT,
  AUTH_LOGIN_ENDPOINT,
  AUTH_LOGOUT_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
} from "../constants/endpoints";

type AuthPayloadType = {
  email: string;
  password: string;
};
type AuthLogoutPayloadType = {
  token: string;
};
type UpdatePasswordPayloadTypes = {
  email: string;
  newPassword: string;
};

export function authenticateUser(payload: AuthPayloadType) {
  return axios.post(AUTH_LOGIN_ENDPOINT, payload);
}

export function logoutUser(payload: AuthLogoutPayloadType) {
  return axios.post(AUTH_LOGOUT_ENDPOINT, payload);
}

export async function updatePassword(payload: UpdatePasswordPayloadTypes) {
  const res = await axios.put(UPDATE_PASSWORD_ENDPOINT, payload);
  return res;
}

export function forgotPassword(email: string) {
  const endpoint = `${FORGOT_PASSWORD_ENDPOINT}${email}`;
  return axios.get(endpoint);
}
