import React from "react";
import { Result, Button, Row } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const handleGoBackHome = () => navigate("/");
  
  return (
    <Row className="flex justify-center items-center h-full">
      <Result
        status="404"
        title="404 - Not Found"
        subTitle="Sorry, the page you are looking for does not exist."
        extra={
          <Button
            onClick={handleGoBackHome}
            type="primary"
            className="h-10  text-black border-1 border-gray-300"
          >
            Go Back Home
          </Button>
        }
      />
    </Row>
  );
};

export default NotFound;
