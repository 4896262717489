import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";


import { getAllRoles, getManagers, updateUserDetails } from "../../services/adminController";
import { isEmpty, validateFirstName, validateLastName, validatePhoneNumber } from "../../utils";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
type AllFormValuesTypes = {
  emailId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phoneNumber?: string | undefined;
  role?: string | undefined | number;
  manager: any;
};
export default function EditUserModal({ open, setOpen, userInfo }: any) {

  const TAG = "EditUserModal: ";
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [allFormValues, setAllFormValues] = useState<AllFormValuesTypes>();
  const [isManagerRequired, setIsManagerRequired] = useState(false);
  const [managerId, setManagerId] = useState<any>("");

  const { mutate, isPending } = useMutation({
    mutationKey: ["updateUserDetails"],
    mutationFn: (payload: any) => updateUserDetails(payload),
    onSuccess: ({ data }, variables, context) => {
      if (data.status === 200) {
        queryClient.invalidateQueries({ queryKey: ["users"], });
        queryClient.invalidateQueries({ queryKey: ["managers"] });
        queryClient.invalidateQueries({ queryKey: ["roles"] });
        queryClient.invalidateQueries({ queryKey: ["userslist"] });
        form.resetFields();
        notification.success({
          message: "Success",
          description:
            data.message === "Success"
              ? "User details updated successfully."
              : data.message,
        });
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Error", description: error.response.data.message });
    },
  });

  const handleOk = () => {
    setConfirmLoading(true);
    form.validateFields().then((res) => {
      const updateUserDetailsPayload = {
        firstName: res?.firstName || "",
        lastName: res?.lastName || "",
        email: res?.emailId || "",
        realmRoles: [res?.role],
        mobileNo: res?.phoneNumber,
        managerId: isManagerRequired ? managerId : 0,
      };
      mutate(updateUserDetailsPayload);
      setOpen(false);
      setConfirmLoading(false);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
    setIsManagerRequired(false);
  };

  const { data: rolesResponse, isPending: isRolesListPending } = useQuery({
    queryKey: ["roles"],
    queryFn: getAllRoles,
    enabled: open,
  });

  const { data: managersResponse, isPending: isManagersListPending } = useQuery({
    queryKey: ["managers"],
    queryFn: getManagers,
    enabled: open,
  });

  const rolesList = rolesResponse?.data;

  useEffect(() => {
    const initalManager = managersResponse?.data.find((item: any) => item.id === userInfo?.managerId);
    form.setFieldsValue({
      firstName: userInfo?.firstName || "",
      lastName: userInfo?.lastName || "",
      role: userInfo?.roleAssign || undefined,
      emailId: userInfo?.email || "",
      phoneNumber: userInfo?.mobileNo || "",
      manager: userInfo?.managerId ? initalManager?.firstName && { key: initalManager?.id || "", value: `${initalManager?.firstName} ${initalManager?.lastName}` || "" } : "",
    });
    setManagerId(userInfo.managerId || "");
  }, [form, userInfo, managersResponse]);

  useEffect(() => {
    setIsManagerRequired(userInfo.roleAssign === "Agent" || userInfo?.roleAssign === "Legal-Agent");
  }, [userInfo]);

  const handleRoleChange = (value: string) => {
    form.setFieldsValue({ manager: undefined });
    if (value === "Agent" || value === "Legal-Agent") {
      setIsManagerRequired(true);
    } else {
      setIsManagerRequired(false);
    }
  };

  const managerDefaultValue = userInfo?.roleAssign !== "Agent-Manager" ? userInfo?.managerId : undefined;

  const getFilteredManagersList = () => {

    //why this code
    // if (userInfo?.roleAssign === "Agent-Manager") {
    //   const retManagers = managersResponse?.data.filter((item: any) => item.id !== userInfo?.id);
    //   console.log(TAG + "retManagers ", retManagers);
    //   return retManagers;
    // }

    let roleToFilter: string = "";
    if (userInfo?.roleAssign === "Agent") roleToFilter = "Agent-Manager";
    if (userInfo?.roleAssign === "Legal-Agent") roleToFilter = "Legal-Manager";

    const retManagers = managersResponse?.data.filter((item: any) => item.roleAssign === roleToFilter);
    return isEmpty(retManagers) === true ? [] : retManagers;

  };
  const filteredManagersList = getFilteredManagersList();

  const { data: userlistResponse } = useQuery({
    queryKey: ["userslist"],
    queryFn: () => axios.get("/admin/get-users"),
  });

  const isSingleAdminExist = () => {
    if (userlistResponse?.data?.status) {
      const list = userlistResponse?.data?.data.filter((item: any) => item.roleAssign === "Admin");
      return list?.length === 1;
    }
    return false;
  };

  const isRoleFieldDisabled = isSingleAdminExist() && userInfo?.roleAssign === "Admin";


  
  // console.log(TAG + "managerId ", managerId);
  // console.log(TAG + "userInfo ", userInfo);
  // console.log(TAG + "rolesList ", rolesList);
  // console.log(TAG + "isManagerRequired ", isManagerRequired);
  // console.log(TAG + "filteredManagersList ", filteredManagersList);

  // console.log(TAG + "rolesResponse ", rolesResponse);
  // console.log(TAG + "managersResponse ", managersResponse);
  // console.log(TAG + "userlistResponse ", userlistResponse);



  return (
    <Modal
      open={open}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={null}
    >
      <Card
        title="Edit User Details"
        loading={isManagersListPending || isRolesListPending}
      >
        <Form
          {...layout}
          form={form}
          name="update-user"
          style={{ maxWidth: 600 }}
          key={userInfo?.id}
          onValuesChange={(changedValue, allValues) => {
            setAllFormValues(allValues);
            if ((changedValue?.role && (changedValue?.role === "Agent" || changedValue?.role === "Legal-Agent")) || changedValue.manager) {
              setIsManagerRequired(true);
            }
          }}
          onFinish={handleOk}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { required: true },
              {
                validator: validateFirstName,
              },
            ]}
            initialValue={userInfo?.firstName || ""}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              { required: true },
              {
                validator: validateLastName,
              },
            ]}
            initialValue={userInfo?.lastName || ""}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true }]}
            initialValue={userInfo?.roleAssign || []}
          >
            <Select
              disabled={isRoleFieldDisabled}
              placeholder="Select a Role"
              onChange={(value) => handleRoleChange(value)}
            >
              {rolesList?.map((item: any) => {
                return (<Option key={item.id} value={item.name}>{item.name}</Option>);
              })}
            </Select>
          </Form.Item>

          {isManagerRequired && (
            <Form.Item
              name="manager"
              label="Manager"
              rules={[{ required: true }]}
            >
              <Select
                defaultValue={managerDefaultValue === 0 ? undefined : managerDefaultValue}
                placeholder="Select Manager"
                onChange={(e, options: any) => setManagerId(options.key)}
              >
                {filteredManagersList?.map((item: any) => {
                  return (
                    <Option key={item?.id} value={item?.id}>
                      {`${item?.firstName} ${item?.lastName}`}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="emailId"
            label="Email ID"
            initialValue={userInfo?.email || ""}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[{ required: true }, { validator: validatePhoneNumber }]}
            initialValue={userInfo?.mobileNo || ""}
          >
            <Input allowClear maxLength={10} />
          </Form.Item>
          <Row className="flex justify-end gap-2">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              loading={isPending}
              htmlType="submit"
              type="primary"
              disabled={!form.isFieldsTouched() || allFormValues === undefined}
            >
              Update
            </Button>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
}
