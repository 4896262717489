import {
  Button,
  Col,
  Divider,
  Row,
  Layout,
  Typography,
  notification,
} from "antd";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import { useMutation } from "@tanstack/react-query";
import { logoutUser } from "../../services/auth";
import { useAuth } from "../../contexts/AuthContext";
import { getUserData } from "../../utils";

const { Header } = Layout;
const { Text } = Typography;

type NavbarTypes = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
};


const Navbar = ({ collapsed, setCollapsed }: NavbarTypes) => {
  const userData = getUserData();
  const { processLogout } = useAuth();
  const token: string | "" = userData?.refresh_token || "";
  const { mutate, isPending } = useMutation({
    mutationKey: ["logout", token],
    mutationFn: () => logoutUser({ token }),
    onSuccess: ({ data, status }, variables, context) => {
      if (status === 200) {
        processLogout();
      }
    },
    onError: (error) => {
      processLogout()
    },
  });

  return (
    <Header className="pt-0 bg-transparent pl-4 pr-4">
      <Row className="flex justify-between pl-0 pt-6">
        <Col className="flex items-center">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            className="text-xl w-1 h-16"
          />
        </Col>
        <Col className="flex">
          <Col className="flex items-center  ">
            <Col className="flex flex-col justify-end">
              <Text className="font-bold">{userData?.fullName || ""}</Text>
              <Col className="flex justify-end border-t-2">
                <Text >{userData?.roleName || ""}</Text>
              </Col>
            </Col>
            <Divider type="vertical" />
          </Col>
          <Col className="flex items-center pr-3">
            <Button
              disabled={isPending}
              loading={isPending}
              type="primary"
              block
              className="pl-1 pr-1"
              onClick={() => mutate()}
            >
              Logout
            </Button>
          </Col>
        </Col>
      </Row>
    </Header>
  );
};

export default Navbar;
