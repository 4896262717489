import { useEffect, useState } from "react";
import { Card, Modal, Row, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { getTranscript } from "../../services/agentController";

export const TranscriptModal = (props: any) => {
  const { isVisible, handleClose, callId, caseId } = props;
  const [data, setData] = useState<any>([""]);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    (async () => {
      setIsPending(true);
      if (callId && caseId) {
        const res = await getTranscript(callId, caseId);
        if (res?.status === 200) {
          setData(res.data.data);
          setIsPending(false);
        }
      } else {
        setData([""]);
      }
    })();
  }, [callId, caseId]);
  const columns: any = [
    {
      title: "",
      dataIndex: "transcript",
      render: (item: any, tranScript: any) => <Row>{tranScript}</Row>,
    },
  ];
  return (
    <Modal open={isVisible} footer={null} closable={false}>
      <Card
        loading={isPending}
        title="Transcript"
        extra={
          <CloseOutlined
            className="text-lg cursor-pointer"
            onClick={handleClose}
          />
        }
      >
        <Row>
          <Table
            showHeader={false}
            loading={isPending}
            columns={columns}
            dataSource={data}
            size="middle"
            pagination={false}
            scroll={{ y: 300 }}
          />
        </Row>
      </Card>
    </Modal>
  );
};
