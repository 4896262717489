import { Button, Modal, Typography } from "antd";
interface Props {
  status: string;
  isVisible: boolean;
  handleVisibility: () => void;
}
export const InitiateCallStatusModal = (props: Props) => {
  const { status, isVisible, handleVisibility } = props;
  return (
    <Modal
      title="Info"
      closable={false}
      open={isVisible}
      footer={
        <Button type="primary" onClick={handleVisibility}>
          Ok
        </Button>
      }
    >
      <Typography.Text>
        {status || "Something went wrong. Please contact the administration."}
      </Typography.Text>
    </Modal>
  );
};
