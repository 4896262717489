import { Button, Card, Form, Input, Row, Typography, notification } from "antd";
import { useMutation } from "@tanstack/react-query";
import { updatePassword } from "../../services/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { getUserData, validatePassword } from "../../utils";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
export const UpdatePasswordModal = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [allValues, setAllValues] = useState<{
    confirm: undefined | string;
    password: undefined | string;
  }>();
  const userData = getUserData();
  const { processLogout } = useAuth();
  const { mutate, isPending } = useMutation({
    mutationKey: ["updatePassword"],
    mutationFn: updatePassword,
    onSuccess: (res, variables, context) => {
      if (res.status === 200) {
        notification.success({
          message: "Success",
          description: res.data.data.message,
        });
        processLogout();
      }
    },
    onError: (error: any) => {
      notification.error({
        message: "Error",
        description: error.response.data.message,
      });
    },
  });
  const payload = {
    email: userData?.user_name || "",
    newPassword: allValues?.password || "",
  };
  const isBtnDisabled =
    allValues?.confirm !== allValues?.password || allValues === undefined;
  return (
    <div className="login-page flex h-screen items-center justify-center">
      <Card
        title="Update Password"
        extra={<Row></Row>}
        style={{ maxWidth: 600 }}
      >
        <Row className="pb-2 flex-wrap w-100 pl-6 text-start">
          <Typography.Text type="secondary">
            Your password must be at least 12 characters long and should have at
            least 1 uppercase letter, 1 lowercase letter, 1 number, and 1
            symbol.
          </Typography.Text>
        </Row>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={() => mutate(payload)}
          initialValues={{}}
          style={{ maxWidth: 600 }}
          scrollToFirstError
          onValuesChange={(changedValue, allValues) => setAllValues(allValues)}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                validator: validatePassword,
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item className="flex justify-end">
            <Button
              type="primary"
              htmlType="submit"
              loading={isPending}
              disabled={isBtnDisabled}
            >
              Update Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
