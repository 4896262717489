import { Tabs } from "antd";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import {
  getAgentManagerActiveCases,
  getAgentManagerCompletedCases,
  getAgentManagerPendingCases,
} from "../../services/agentManagerController";
import AppLayout from "../Layout";
import { useGlobalStore } from "../../contexts/StoreContext";
import { AgentManagerCasesTable } from "./Tables/AgentManagerCasesTable";
import { getCasePayload } from "../../utils";

const AgentManagerDashboard = () => {
  const { state, dispatch } = useGlobalStore();
  const { activeTabKey } = state;
  const payload = getCasePayload(state);
  const {
    data: activeCases,
    isPending: isActiveCasesPending,
    isRefetching: isActiveCasesRefetching,
  } = useQuery({
    queryKey: ["agentManagerActiveCases", activeTabKey, payload],
    queryFn: () => getAgentManagerActiveCases(payload),
    enabled: activeTabKey === "2",
    placeholderData: keepPreviousData,
  });

  const {
    data: pendingCases,
    isPending: isPendingCasesPending,
    isRefetching: isPendingCasesRefetching,
  } = useQuery({
    queryKey: ["agentManagerPendingCases", activeTabKey, payload],
    queryFn: () => getAgentManagerPendingCases(payload),
    enabled: activeTabKey === "1",
    placeholderData: keepPreviousData,
  });

  const {
    data: completedCases,
    isPending: isCompletedCasesPending,
    isRefetching: isCompletedCasesRefetching,
  } = useQuery({
    queryKey: ["agentManagerCompletedCases", activeTabKey, payload],
    queryFn: () => getAgentManagerCompletedCases(payload),
    enabled: activeTabKey === "3",
    placeholderData: keepPreviousData,
  });

  const items = [
    {
      label: "Pending Case",
      key: "1",
      children: (
        <AgentManagerCasesTable
          totalRecords={pendingCases?.totalResults}
          data={(pendingCases?.data && pendingCases?.data) || []}
          isLoading={isPendingCasesPending || isPendingCasesRefetching}
          status_type="pending"
        />
      ),
    },
    {
      label: "Active Cases",
      key: "2",
      children: (
        <AgentManagerCasesTable
          totalRecords={activeCases?.totalResults}
          data={(activeCases?.data && activeCases?.data) || []}
          isLoading={isActiveCasesPending || isActiveCasesRefetching}
          status_type="active"
        />
      ),
    },
    {
      label: "Resolved Cases",
      key: "3",
      children: (
        <AgentManagerCasesTable
          totalRecords={completedCases?.totalResults}
          data={(completedCases?.data && completedCases?.data) || []}
          isLoading={isCompletedCasesPending || isCompletedCasesRefetching}
          status_type="completed"
        />
      ),
    },
  ];
  
  const setActiveTabKey = (e: string) => {
    dispatch({ type: "SET_ACTIVE_TAB_KEY", payload: e });
  };

  return (
    <AppLayout moduleName="Portfolio">
      <Tabs
        defaultActiveKey={state.activeTabKey}
        items={items}
        onChange={setActiveTabKey}
      ></Tabs>
    </AppLayout>
  );
};

export default AgentManagerDashboard;
