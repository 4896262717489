import { createBrowserRouter } from "react-router-dom";

import Portfolio from "../pages/Portfolio";
import NotFound from "../pages/NotFound";
import Maintenance from "../pages/Maintenance";
import Login from "../pages/Login";
import Admin from "../pages/Admin";
import DataUpload from "../pages/DataUpload";
import { ForgotPassword } from "../pages/ForgotPassword";
import Settings from "../pages/Settings";

import RootLayout from "../RootLayout";
import PrivateRoutes from "./PrivateRoutes";
import { UpdatePasswordModal } from "../components/UpdatePassword";
import CaseDetails from "../components/CaseDetails";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: "/forgot-password",
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [{ index: true, element: <ForgotPassword /> }],
  },
  {
    path: "/maintenance",
    element: <Maintenance />,
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: "/",
        element: <Portfolio />,
      },
      {
        path: "/portfolio/:id",
        element: <CaseDetails />,
      },
      {
        path: "/user-management",
        element: <Admin />,
      },
      {
        path: "/data-upload",
        element: <DataUpload />,
      },
      {
        path: "/update-password",
        element: <UpdatePasswordModal />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
