import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { validatePassword } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "../../services/commonController";
import { useAuth } from "../../contexts/AuthContext";
import { InfoCircleOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const ChangePasswordModal = ({
  isModalVisible,
  handleModalVisibility,
}: any) => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const { processLogout } = useAuth();
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const { mutate } = useMutation({
    mutationKey: ["change-password"],
    mutationFn: resetPassword,
    onSuccess: (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({
          message: "Success",
          description: res.data.message,
        });
        processLogout();
      }
    },
    onError: (error: any) => {
      notification.error({
        message: "Failed",
        description: error.response.data.message,
      });
    },
  });
  return (
    <Modal open={isModalVisible} onCancel={handleModalVisibility} footer={null}>
      <Card title="Change Password">
        <Row className="pb-2 flex-wrap w-100 pl-6 text-start">
          <Typography.Text type="secondary">
            Your password must be at least 12 characters long and should have at
            least 1 uppercase letter, 1 lowercase letter, 1 number, and 1
            symbol.
          </Typography.Text>
          <Typography.Text type="secondary">
            After changing your password, you will be logged out and redirected
            to the login page.
          </Typography.Text>
        </Row>
        <Form
          {...layout}
          form={form}
          name="change-password"
          onFinish={(values) =>
            mutate({
              newPassword: values.newPassword,
              oldPassword: values.oldPassword,
            })
          }
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              { required: true, message: "Please enter old password" },
              {
                validator: validatePassword,
              },
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Enter your password"
              className="h-10"
            />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please enter new password" },
              {
                validator: validatePassword,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("oldPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The new password must be different from the old password!"
                    )
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Enter your password"
              className="h-10"
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmedNewPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm new password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Enter your password"
              className="h-10"
            />
          </Form.Item>
          <Row className="flex justify-end gap-2">
            <Button onClick={handleModalVisibility}>Cancel</Button>
            <Button
              // loading={isPending}
              htmlType="submit"
              type="primary"
              disabled={!submittable}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};
