import { Table, Tooltip, Typography } from "antd";
import { getColorForStatus } from "../utils";

type DataType = {
  key: React.Key;
  username: string;
  fullName: string;
  email: string;
};

export const BorrowerDetails = ({
  borrowerViewDetails,
  isCaseDetailsPending,
}: any) => {
  const dpdStatus = borrowerViewDetails?.dpdStatus?.split(" ")[1];

  const dpdStatusBgColor = getColorForStatus(
    borrowerViewDetails?.dpdStatus || ""
  );

  const data: any = [
    {
      loanIdLabel: <span className="font-bold">Loan ID</span>,
      loanId: <span>{borrowerViewDetails?.loanId || "N/A"}</span>,
      dateOfPurchaseLabel: <span className="font-bold">Lender</span>,
      dateOfPurchase: <span>{borrowerViewDetails?.clientName || "N/A"}</span>,
      otherChargesLabel: <span className="font-bold">Product Name</span>,
      otherCharges: <span>{borrowerViewDetails?.productName || "N/A"}</span>,
    },
    {
      loanIdLabel: <span className="font-bold">Date of Purchase</span>,
      loanId: <span>{borrowerViewDetails?.dateOfPurchase || "N/A"}</span>,
      dateOfPurchaseLabel: <span className="font-bold">DPD Status</span>,
      dateOfPurchase: (
        <span>
          <Tooltip title={borrowerViewDetails?.dpdStatus || "N/A"}>
            <Typography.Text
              style={{
                background: dpdStatusBgColor,
                padding:
                  dpdStatus === "0-30" ? ".25rem 1.30rem" : " .25rem 1rem",
                borderRadius: "12px",
                maxWidth: "1rem",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {dpdStatus || "N/A"}
            </Typography.Text>
          </Tooltip>
        </span>
      ),
      otherChargesLabel: <span className="font-bold">Loan Amount</span>,
      otherCharges: (
        <span>
          {" "}
          {borrowerViewDetails?.loanAmount
            ? `₹ ${borrowerViewDetails?.loanAmount}`
            : "N/A"}
        </span>
      ),
    },
    {
      loanIdLabel: <span className="font-bold">Due Amount</span>,
      loanId: (
        <span>
          {" "}
          {borrowerViewDetails?.dueAmount
            ? `₹ ${borrowerViewDetails?.dueAmount}`
            : "N/A"}
        </span>
      ),
      dateOfPurchaseLabel: <span className="font-bold">Interest Amount</span>,
      dateOfPurchase: (
        <span>
          {borrowerViewDetails?.interestAmount
            ? `₹ ${borrowerViewDetails?.interestAmount}`
            : "N/A"}
        </span>
      ),
      otherChargesLabel: <span className="font-bold"> Other Charges</span>,
      otherCharges: (
        <span>
          {borrowerViewDetails?.otherCharges
            ? `₹ ${borrowerViewDetails?.otherCharges}`
            : "N/A"}
        </span>
      ),
    },
  ];
  const sharedOnCell = (_: DataType, index?: number) => {
    if (index === 3) {
      return { colSpan: 0 };
    }

    return {};
  };
  const columns: any = [
    {
      title: <span>Loan ID Lable</span>,
      dataIndex: "loanIdLabel",
    },
    {
      title: "Loan ID",
      dataIndex: "loanId",
      onCell: (_: any, index: any) => ({
        colSpan: index === 3 ? 5 : 1,
      }),
    },
    {
      title: "Date of Purchase Label",
      dataIndex: "dateOfPurchaseLabel",
      onCell: sharedOnCell,
    },
    {
      title: "Date of Purchase",
      dataIndex: "dateOfPurchase",
      onCell: sharedOnCell,
    },
    {
      title: "Other Charges Label",
      dataIndex: "otherChargesLabel",
      onCell: sharedOnCell,
    },
    {
      title: "Other Charges ",
      dataIndex: "otherCharges",
      onCell: sharedOnCell,
    },
  ];

  return (
    <Table
      loading={isCaseDetailsPending}
      className="borrower-details-table"
      showHeader={false}
      columns={columns}
      dataSource={data}
      size="middle"
      pagination={false}
    />
  );
};
