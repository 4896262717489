import dayjs from "dayjs";
import { useState } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { RadioChangeEvent } from 'antd';

import {
  Button,
  Row,
  Col,
  Form,
  Card,
  Input,
  Modal,
  Select,
  Tooltip,
  Typography,
  DatePicker,
  notification,
  Radio
} from "antd";

import { getCallResponseList, postCallResponse } from "../../services/agentController";
import { decryptData, isEmpty, delay } from "../../utils";
import { PlayAudioMpdal } from "../PlayAudioModal";
import { CallResponseSubmitted } from "../../constants/successNotificationText";
import { getRecordingUrl } from "../../services/commonController";

const { Option } = Select;
const { Text } = Typography;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export function CallResponseModal(props: any) {

  const TAG = "CallResponseModal: ";

  const [action, setAction] = useState<any>("");

  const [partialAmountNextTime, setPartialAmountNextTime] = useState<any>("");
  const [callbackAfterTime, setCallbackAfterTime] = useState<any>("");
  const [howManyDaysAfterTime, setHowManyDaysAfterTime] = useState<any>("");

  const [nextPaymentDate, setNextPaymentDate] = useState<any>("");

  const [selectedResponse, setSelectedResponse] = useState<any>({});
  const [isPlayAudioModalVisible, setIsPlayAudioModalVisible] = useState(false);
  const [recordingUrl, setRecordingUrl] = useState("");
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { setOpenCallResponseModal, data } = props;

  const disabledDate = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  const disabledDateInDateOnly = (current: any) => {
    return current && current <= new Date().setHours(0, 0, 0, 0);
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disableFutureTimes = (current: any) => {
    const isToday = current && current.isSame(dayjs(), 'day');
    if (isToday) {
      return {
        disabledHours: () => {
          const hours = [];
          for (let i = 0; i < dayjs().hour(); i += 1) hours.push(i);
          return hours;
        },
        disabledMinutes: (selectedHour: any) => {
          const minutes = [];
          if (selectedHour === dayjs().hour()) {
            for (let i = 0; i < dayjs().minute(); i += 1) minutes.push(i);
          }
          return minutes;
        },
        disabledSeconds: () => []
      }
    };
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => []
    };
  }


  const { data: callResponse } = useQuery({
    queryKey: ["call-response-list"],
    queryFn: () => getCallResponseList(props.caseId || ""),
    enabled: props.open,
  });

  const isAgreedAmountFieldVisible = selectedResponse?.value === 4;
  const isHowManyDaysFieldVisible = selectedResponse?.value === 3;
  const isCallBackAfterFieldVisible = selectedResponse?.value === 1;
  const isNegotiationsOn = selectedResponse?.value === 9;
  const isPlayIconDisabled = !data?.recordingAvailable;
  const cursorType = isPlayIconDisabled ? "not-allowed" : "pointer";

  const { mutate, isPending } = useMutation({
    mutationKey: ["submit-call-response"],
    mutationFn: postCallResponse,
    onSuccess: async (res, variables, context) => {
      if (res.data.status === 200) {
        notification.success({ message: res.data.message, description: CallResponseSubmitted });
        queryClient.invalidateQueries({ queryKey: ["case-details"] });
        queryClient.invalidateQueries({ queryKey: ["call-logs"] });
        queryClient.invalidateQueries({ queryKey: ["audit-logs"] });
        await delay(400);
        await queryClient.invalidateQueries({ queryKey: ["case-details"] });
        setOpenCallResponseModal(false);
        window.onbeforeunload = null;
        setSelectedResponse({});
        form.resetFields();
      }
    },
    onError: (error: any) => {
      notification.error({ message: "Failed", description: error.response.data.message });
      window.onbeforeunload = null;
    },
  });

  const handlePlayAudioModalVisibility = () => {
    setIsPlayAudioModalVisible((prev) => !prev);
    setRecordingUrl("");
  };

  const { mutate: fetchRecordingUrl } = useMutation({
    mutationKey: ["get-recordingUrl"],
    mutationFn: getRecordingUrl,
    onSuccess: (data, variables, context) => {
      if (data.status === 200) {
        // setIsPlayAudioModalVisible(true);
        // setIsRecordingUrlFetched(true);
        setRecordingUrl(data.data.data);
      }
    },
  });

  // console.log(TAG + " selectedResponse ", selectedResponse);
  // console.log(TAG + " isNegotiationsOn ", isNegotiationsOn);
  // console.log(TAG + " nextPaymentDate ", nextPaymentDate);
  // console.log(TAG + " isAgreedAmountFieldVisible ", isAgreedAmountFieldVisible);
  // console.log(TAG + " isCallBackAfterFieldVisible ", isCallBackAfterFieldVisible);
  // console.log(TAG + " isHowManyDaysFieldVisible ", isHowManyDaysFieldVisible);

  return (
    <Modal width={900} open={props.open} footer={null} closable={false}>
      <Card title="Call Response">
        <Form
          {...layout}
          form={form}
          name="callResponse-form"
          onFinish={(values) => {

            if (isAgreedAmountFieldVisible == true) {
              const combine_action = `${String(action)},${String(partialAmountNextTime)} 09:00`; //Time 9 is default for now.
              // console.log(TAG + " combine_action: ", combine_action);
              mutate({
                logId: props.data.logId,
                responseId: values.callResponse,
                action: combine_action
              });
            } else if (isHowManyDaysFieldVisible == true) {
              const combine_action_two = `${String(howManyDaysAfterTime)} 09:00`; //Time 9 is default for now.
              // console.log(TAG + " combine_action_two: ", combine_action_two);
              mutate({
                logId: props.data.logId,
                responseId: values.callResponse,
                action: combine_action_two
              });
            } else if (isCallBackAfterFieldVisible == true) {
              const combine_action_three = `${String(callbackAfterTime)}`;
              // console.log(TAG + " combine_action_three: ", combine_action_three);
              mutate({
                logId: props.data.logId,
                responseId: values.callResponse,
                action: combine_action_three
              });
            } else {
              mutate({
                logId: props.data.logId,
                responseId: values.callResponse,
                action: action
              })
            }

            // console.log(values);
            // console.log(action);

          }
          }
        >
          <div>
            <Row className="flex pb-4">
              <Col className="flex gap-3" span={8}>
                <Text className="font-bold">Customer Name:</Text>
                <Text>{decryptData(props.data?.customerName) || "N/A"}</Text>
              </Col>
              <Col className="flex gap-3" span={8}>
                <Text className="font-bold">Date of Call:</Text>
                <Text>{props.data?.callDate || "N/A"}</Text>
              </Col>
              <Col className="flex gap-3" span={8}>
                <Text className="font-bold">Agent Name:</Text>
                <Text>{props.data?.agentName || "N/A"}</Text>
              </Col>
            </Row>

            <Row className="flex pb-4">
              <Col className="flex gap-3" span={8}>
                <Text className="font-bold">Call From:</Text>
                <Text>{props.data?.callFrom || "N/A"}</Text>
              </Col>
              <Col className="flex gap-3" span={8}>
                <Text className="font-bold">Call To:</Text>
                <Text>{props.data?.callTo || "N/A"}</Text>
              </Col>
              <Col className="flex gap-3" span={8}>
                <Text className="font-bold">Call Recording:</Text>
                <Tooltip
                  title={!isPlayIconDisabled ? "Play recording!" : "Recording is not available!"} >
                  {isPlayIconDisabled ? (
                    <PlayCircleOutlined
                      style={{ color: "gray", cursor: cursorType }}
                      className="text-xl"
                    />
                  ) : (
                    <div
                      style={{
                        cursor: cursorType,
                        color: isPlayIconDisabled ? "gray" : "#444444",
                      }}
                      onClick={() => {
                        handlePlayAudioModalVisibility();
                        fetchRecordingUrl({
                          callId: props.data.callUuid,
                          caseId: props.caseId,
                        });
                      }}
                      className="text-xl"
                    >
                      <PlayCircleOutlined />
                    </div>
                  )}
                </Tooltip>
              </Col>
            </Row>

            <Form.Item
              name="callResponse"
              label={<Text className="font-bold">Call Response</Text>}
              rules={[{ required: true }]}
            >
              <Select
                onChange={(value, options) => setSelectedResponse(options)}
              >
                {callResponse?.data?.data?.map(
                  ({ response, responseId }: any) => {
                    return (<Option key={responseId} value={responseId}> {response} </Option>);
                  }
                )}
              </Select>
            </Form.Item>
          </div>

          <>

            {isCallBackAfterFieldVisible && (
              <Form.Item
                name="callbackAfter"
                label={<Text className="font-bold">Call Back After</Text>}
                rules={[{ required: true, message: "Please enter date and time" }]}
              >
                <DatePicker
                  className="w-full"
                  format="YYYY-MM-DD HH:mm"
                  disabledDate={disabledDate}
                  disabledTime={disableFutureTimes}
                  name="callback-date"
                  id="callback-date"
                  showTime={{ format: "hh:mm A" }}
                  value={callbackAfterTime}
                  onChange={(dates, dateString) => setCallbackAfterTime(dateString)}
                />
              </Form.Item>
            )}

            {isHowManyDaysFieldVisible && (
              <Form.Item
                name="paybackAfter"
                label={<Text className="font-bold">Payback date</Text>}
                rules={[{ required: true, message: "Please enter date" }]}
              >
                <DatePicker
                  className="w-full"
                  format="YYYY-MM-DD"
                  name="payback-date"
                  id="payback-date"
                  disabledDate={disabledDateInDateOnly}
                  value={howManyDaysAfterTime}
                  onChange={(dates, dateString) => setHowManyDaysAfterTime(dateString)}
                />
              </Form.Item>
            )}

            {isAgreedAmountFieldVisible && (
              <>
                <Form.Item
                  name="agreedAmount"
                  label={<Text className="font-bold">Amount agreed</Text>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter amount.",
                    },
                    () => ({
                      validator(_, value) {
                        if (value === "0" || value === "00") {
                          return Promise.reject(
                            new Error("Please enter valid amount.")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    maxLength={10}
                    onChange={(e) => setAction(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="nextPaymentDate"
                  label={<Text className="font-bold">Next payment date</Text>}
                  rules={[{ required: true, message: "Please enter date" }]}
                >
                  <DatePicker
                    className="w-full"
                    format="YYYY-MM-DD"
                    name="next-payment-date"
                    id="next-payment-date"
                    disabledDate={disabledDateInDateOnly}
                    value={partialAmountNextTime}
                    onChange={(dates, dateString) => setPartialAmountNextTime(dateString)}
                  />
                </Form.Item>
              </>
            )}

            {isNegotiationsOn && (
              <>
                <Form.Item
                  name="negotiationResult"
                  label={<Text className="font-bold">Agree To Negotiated Solution?</Text>}
                  rules={[{ required: true, message: "Please select one option" }]}
                >
                  <Radio.Group onChange={(e: RadioChangeEvent) => setAction(e.target.value)}>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}

          </>
          <Row className="flex justify-end pt-4">
            <Button type="primary" htmlType="submit" loading={isPending}>
              Submit Response
            </Button>
          </Row>
        </Form>
        <PlayAudioMpdal
          caseId={props.caseId || ""}
          callId={props.data.callUuid}
          recordingUrl={recordingUrl}
          isVisible={isPlayAudioModalVisible}
          handleModalVisibility={handlePlayAudioModalVisibility}
        />
      </Card>
    </Modal>
  );
}
