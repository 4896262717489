import React, {
  createContext,
  useContext,
  useReducer,
  PropsWithChildren,
} from "react";

import { isEmpty } from "../../utils";


type CasesPayloadType = {
  dpdStatus: string;
  endDate: string;
  maxDueAmount: string;
  maxLoanAmount: string;
  minDueAmount: string;
  minLoanAmount: string;
  nbfc: string;
  page: number;
  size: number;
  startDate: string;
  status: string;
  sortBy: string;
  sortOrder: string;
};
interface GlobalState {
  userInfo: any;
  activeTabKey: string;
  isFilterActive: boolean;
  casePayload: CasesPayloadType;
  bucketName: string;
  activeSidebarKey: string;
  activeCasePayload: CasesPayloadType;
  pendingCasePayload: CasesPayloadType;
  completedCasePayload: CasesPayloadType;
}
const payload = {
  dpdStatus: "",
  endDate: "",
  maxDueAmount: "",
  maxLoanAmount: "",
  minDueAmount: "",
  minLoanAmount: "",
  nbfc: "",
  page: 1,
  size: 10,
  startDate: "",
  status: "",
  sortBy: "",
  sortOrder: "",
};
const initialState: GlobalState = {
  userInfo: {},
  activeTabKey: "1",
  isFilterActive: false,
  casePayload: payload,
  bucketName: isEmpty(localStorage.getItem("caseType")) == true ? "" : String(localStorage.getItem("caseType")),
  activeSidebarKey: "1",
  activeCasePayload: payload,
  pendingCasePayload: payload,
  completedCasePayload: payload,
};

type Action =
  | { type: "SET_ACTIVE_TAB_KEY"; 
      payload: string 
    }
  | {
      type: "SET_FILTER_FLAG";
      payload: boolean;
    }
  | {
      type: "SET_CASE_PAYLOAD";
      payload: CasesPayloadType;
    }
  | {
      type: "SET_BUCKET_NAME";
      payload: string;
    }
  | { type: "RESET_STORE" }
  | {
      type: "SET_SIDEBAR_ACTIVE_KEY";
      payload: string;
    }
  | {
      type: "SET_ACTIVE_CASE_PAYLOAD";
      payload: CasesPayloadType;
    }
  | {
      type: "SET_PENDING_CASE_PAYLOAD";
      payload: CasesPayloadType;
    }
  | {
      type: "SET_COMPLETED_CASE_PAYLOAD";
      payload: CasesPayloadType;
    };

export const GlobalStateContext = createContext<
  | {
      state: GlobalState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

const globalReducer = (state: GlobalState, action: Action): GlobalState => {
  switch (action.type) {
    case "SET_ACTIVE_TAB_KEY":
      return { ...state, activeTabKey: action.payload };
    case "SET_FILTER_FLAG":
      return { ...state, isFilterActive: action.payload };
    case "SET_CASE_PAYLOAD":
      return { ...state, casePayload: action.payload };
    case "SET_BUCKET_NAME":
      return { ...state, bucketName: action.payload };
    case "SET_SIDEBAR_ACTIVE_KEY":
      return { ...state, activeSidebarKey: action.payload };
    case "SET_ACTIVE_CASE_PAYLOAD":
      return { ...state, activeCasePayload: action.payload };
    case "SET_PENDING_CASE_PAYLOAD":
      return { ...state, pendingCasePayload: action.payload };
    case "SET_COMPLETED_CASE_PAYLOAD":
      return { ...state, completedCasePayload: action.payload };
    case "RESET_STORE":
      return initialState;
    default:
      return state;
  }
};

export const GlobalStateProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalStore = (): {
  state: GlobalState;
  dispatch: React.Dispatch<Action>;
} => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalStore must be used within a GlobalStateProvider");
  }
  return context;
};
