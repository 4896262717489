import { useState } from "react";
import { Col, Pagination, Row, Table, Tooltip } from "antd";
import { generatePath, useNavigate } from "react-router-dom";
import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";

import { adminCasesTableColumn } from "./Columns";
import { AdvanceFilter } from "../../AdvanceFilter";
import { useGlobalStore } from "../../../contexts/StoreContext";
import { downloadCases } from "../../../services/commonController";
import { getCasePayload, getCasePayloadType, retCasesWord } from "../../../utils";


export function AdminCasesTable({
  data,
  pageSize,
  totalRecords,
  isPending,
  status_type
}: any) {
  const TAG = "AdminCasesTable: ";
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useGlobalStore();

  const toggleAdvanceFilter = () => setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
  const payload = getCasePayload(state);
  const casePayloadType: any = getCasePayloadType(state.activeTabKey);

  const handleOnChange = (page: number, pageSize: number) => {
    dispatch({
      type: casePayloadType,
      payload: {
        ...payload,
        page: page,
        size: pageSize,
      },
    });
  };

  const handleSorting = (sortBy: string, sortOrder: string) => {
    dispatch({
      type: casePayloadType,
      payload: {
        ...payload,
        sortBy: sortBy || "",
        sortOrder: sortOrder || "",
      },
    });
  };

  const getSource = () => {
    let source = "";
    if (state.activeTabKey === "1") {
      source = "active";
    }
    if (state.activeTabKey === "2") {
      source = "completed";
    }
    return source;
  };

  const source = getSource();

  const handleDownload = (source: string) => {
    downloadCases(source)
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${source}Cases.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        console.log("end");
      })
      .catch((error) => {
        console.error("Error downloading the cases:", error);
      });
  };

  // console.log(TAG + "payload ", payload);
  // console.log(TAG + "casePayloadType ", casePayloadType);

  return (
    <Col>

      <Row className="flex justify-end">
        <Col className="p-1 pr-2 gap-3 flex pb-4">
          <Tooltip title={`Filter ${retCasesWord(String(source))} data `}>
            {/* <Tooltip title="Case Filter"> */}
            <FilterOutlined
              className="cursor-pointer text-lg"
              onClick={toggleAdvanceFilter}
            />
          </Tooltip>
          <Tooltip title="Download data">
            <DownloadOutlined
              className="cursor-pointer text-lg"
              onClick={() => handleDownload(source)}
            />
          </Tooltip>
        </Col>
      </Row>

      {isAdvanceFilterOpen && (
        <Row className="pb-4">
          <AdvanceFilter toggleAdvanceFilter={toggleAdvanceFilter} />
        </Row>
      )}

      <Table
        loading={isPending}
        onChange={(selectedRowKeys, selectedRows, info: any) => { handleSorting(info?.field, info?.order); }}
        columns={adminCasesTableColumn}
        dataSource={data || []}
        size="middle"
        pagination={false}
        onRow={(rowInfo) => ({
          onClick: () => {
            localStorage.setItem("caseType", rowInfo?.bucketName);
            const detailsPath = generatePath("/portfolio/:id", { id: rowInfo.key.toString() });
            navigate(detailsPath);
            dispatch({ type: "SET_BUCKET_NAME", payload: rowInfo?.bucketName });
          },
        })}
        style={{ cursor: "pointer" }}
      />

      <Row className="flex justify-center pt-6 pb-6">
        <Pagination
          showSizeChanger
          pageSize={payload.size}
          total={totalRecords}
          onChange={handleOnChange}
          current={payload.page}
        />
      </Row>

    </Col>
  );
}
